/** @jsx jsx */

import { navigate } from 'gatsby'
import { FC, useEffect } from 'react'

import routes from '../constants/routes'

const RegulatoryDisclosures: FC = () => {
  useEffect(() => {
    navigate(`${routes.documents}#regulatory-disclosures`)
  }, [])

  return null
}

export default RegulatoryDisclosures
